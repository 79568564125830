import React, {Component} from 'react';
class VerifyEmailRedirectPage extends Component {


  componentDidMount(){
    var url = `https://us-central1-tripp-prod.cloudfunctions.net/emailConfirmation${window.location.href.substring(window.location.href.indexOf('?'))}`;
    window.location.replace(url);
  }

  render(){
    return (<section>Redirecting...</section>);
  }
}

export default VerifyEmailRedirectPage;
